import { packageTitles, partnerTitleConfig, runningListOfUiTokens } from 'shared/ui/tokenNames';
import { has, get } from 'lodash';
import { isPast } from 'date-fns';
import { Product, QuotePricing } from 'features/quotes/quotes.types';
import { Coverage } from '../features/coverages/coverages.types';
import { OUT_OF_APPETITE_NICHES } from './ui/constants';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export function formatTruncatedCents(cents: unknown): string {
  const dollars = Number(cents) / 100;

  switch (true) {
    case dollars >= 1000000:
      return `$${dollars / 1000000}M`;
    case dollars >= 1000:
      return `$${dollars / 1000}K`;
    default:
      return `$${dollars}`;
  }
}

export const formatCents = (cents: number): string => {
  return formatter.format(cents / 100);
};

export const formatAcceptedCoverages = (acceptedCoverages: Record<string, boolean>): string[] => {
  return Object.entries(acceptedCoverages)
    .map(([name, accepted]) => {
      if (accepted) {
        return runningListOfUiTokens[name];
      } else {
        return null;
      }
    })
    .filter((el) => !!el) as string[];
};

export const formatOptionalCoverages = (acceptedCoverages: Record<string, boolean>): string[] => {
  return Object.entries(acceptedCoverages)
    .map(([name, accepted]) => {
      if (!accepted) {
        return runningListOfUiTokens[name];
      } else {
        return null;
      }
    })
    .filter((el) => !!el) as string[];
};

export const getTotalPremiumWithTaxesandFees = (pricing: QuotePricing): number => {
  const { annual } = pricing;
  const { fees, taxes } = annual;

  const annualTotal = annual.totalPremiumCents;
  return annualTotal + taxes + fees;
};

export const getFilteredOptionalCoverages = (optionalCoverageNames: Array<string>): string => {
  if (optionalCoverageNames.length > 3) {
    return [...optionalCoverageNames.slice(0, 3), 'and more'].join(', ');
  }
  return optionalCoverageNames.join(', ');
};

export const getPackageTitle = (packageSlug: string, referralPartner?: string): string => {
  let packageName;
  if (referralPartner && has(partnerTitleConfig, [referralPartner, 'slug', packageSlug])) {
    packageName = get(partnerTitleConfig, [referralPartner, 'slug', packageSlug]);
  } else {
    packageName = get(packageTitles, [packageSlug, 0], 'Customizable Package');
  }
  return packageName;
};

export const formatListString = (items: string[], joiner: string): string => {
  if (items.length === 1) {
    return items[0];
  } else {
    const lastItem = items.slice(items.length - 1);
    const otherItems = items.slice(0, items.length - 1);
    return `${otherItems.join(', ')} ${joiner} ${lastItem[0]}`;
  }
};

export type CurrentStatus = 'application expired' | 'quote expired' | 'active' | 'loading';
export const getCurrentStatus = (
  applicationExpirationDate: Date | null,
  quoteExpirationDate: Date | null
): CurrentStatus => {
  if (!applicationExpirationDate || !quoteExpirationDate) return 'loading';

  if (isPast(applicationExpirationDate)) return 'application expired';
  if (isPast(quoteExpirationDate)) return 'quote expired';
  return 'active';
};

export const removeBOPWorkFromAnywhere = (
  products: Record<string, Product>,
  providedImplicitElements: Record<string, Coverage>
) => {
  if (products['CPP']) return providedImplicitElements;
  if (typeof providedImplicitElements.bop_work_from_anywhere === typeof undefined)
    return providedImplicitElements;
  const implicitElements = {
    ...providedImplicitElements,
  };
  implicitElements.bop_work_from_anywhere = {
    ...implicitElements.bop_work_from_anywhere,
    visibleToUser: false,
  };
  return implicitElements;
};

export const determinePAIComponent = (providedImplicitElements: Record<string, Coverage>) => {
  const PAI_KEY = 'cpp_gl_pai';
  const PAI_LIMITED_KEY = 'cpp_gl_limit_PAI';

  let pai: Coverage | undefined = providedImplicitElements[PAI_KEY];
  let limitedPai: Coverage | undefined = providedImplicitElements[PAI_LIMITED_KEY];

  if (!pai || !limitedPai) {
    return providedImplicitElements;
  } else {
    pai = { ...pai };
    limitedPai = { ...limitedPai };
    const limitedPaiLimits =
      Object.keys(limitedPai.limits).length > 0 ? limitedPai.limits : pai.limits;
    if (limitedPai.offered) {
      pai.visibleToUser = false;
      limitedPai.visibleToUser = true;
    } else {
      pai.visibleToUser = true;
      limitedPai.visibleToUser = false;
    }
    return {
      ...providedImplicitElements,
      [PAI_KEY]: pai,
      [PAI_LIMITED_KEY]: {
        ...limitedPai,
        limits: limitedPaiLimits,
      },
    };
  }
};

export const hasUserDiscretionExcludedNiche = (primaryNiche: string): boolean => {
  if (!primaryNiche) return false;
  return OUT_OF_APPETITE_NICHES.includes(primaryNiche);
};
