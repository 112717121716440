import { FC } from 'react';
import { Box, Typography } from '@vouch/ui';
import { notOfferedRequestCoverageClicked } from 'features/eventTracking/eventTracking.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPrimaryNiche } from 'features/session/sessionSlice';
import { hasUserDiscretionExcludedNiche } from 'shared/helpers';

type NotOfferedType = {
  hasActiveDiscretion: boolean;
  groupToken?: string;
};
export const NotOffered: FC<NotOfferedType> = ({ hasActiveDiscretion, groupToken }) => {
  const dispatch = useDispatch();
  const discretionEnabled = !hasUserDiscretionExcludedNiche(useSelector(getPrimaryNiche));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRequestBelowClick = (e: any) => {
    e.stopPropagation();

    dispatch(notOfferedRequestCoverageClicked({ groupToken }));
    document.getElementById('note-to-underwriter-field')?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Box className="card-middle" />
      <Box className="coverage-details"></Box>
      <Box className="coverage-acceptance">
        {hasActiveDiscretion ? (
          <Typography
            data-testid="not-offered-title"
            sx={(theme) => ({ color: theme.designTokens.colorNeutralDark, fontSize: '0.875rem' })}
          >
            Not available
          </Typography>
        ) : (
          <Typography
            sx={(theme) => ({ color: theme.designTokens.colorNeutralDark, fontSize: '0.875rem' })}
            data-testid="not-offered-text"
            variant="subtitle2"
          >
            Not included
            <br />
            {discretionEnabled ? (
              <>
                Need this coverage?
                <br />
                <Box
                  className="request-link"
                  role="button"
                  tabIndex={0}
                  onClick={handleRequestBelowClick}
                  onKeyDown={handleRequestBelowClick}
                  sx={(theme) => ({ color: theme.designTokens.colorPrimaryDarker })}
                >
                  Request it below
                </Box>
              </>
            ) : (
              ''
            )}
          </Typography>
        )}
      </Box>
    </>
  );
};
