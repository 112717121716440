export const OUT_OF_APPETITE_NICHES = [
  'Other_Services',
  'Business_Consulting_Services',
  'General_Prof_Service',
  'Financial_Services',
  'Construction_Contractor',
  'Real_Estate_Owner_Operator',
  'Engineering_Services',
  'Legal_Services',
  'Accounting_Services',
  'Insurance_Services',
  'Real_Estate_Services',
  'Architecture_Service',
  'Plant_Touching_Cannabis_Goods',
  'Crypto_Software_Mining_Distro_Storage',
  'Blockchain_Software_Mining_Distro_Storage',
  'Coworking_Operator',
];
