import { createAction } from '@reduxjs/toolkit';
import { Maybe } from 'generated/graphql';

export const aiCoverageBannerViewed = createAction('aiCoverageBannerViewed');
export const aiCoverageRequestButtonClicked = createAction('aiCoverageRequestNowButtonClicked');
export const backToPackages = createAction<Record<string, string>>('backToPackages');
export const changeEffectiveDate =
  createAction<Record<string, string | undefined>>('changeEffectiveDate');
export const chatWidgetClicked = createAction<Record<string, unknown>>('chatWidgetClicked');
export const chatWidgetAutoOpened = createAction<Record<string, unknown>>('chatWidgetAutoOpened');
export const coverageCardClicked = createAction<Record<string, string>>('coverageCardClicked');
export const coverageTabChange = createAction<Record<string, string>>('coverageTabChange');
export const discretionRequested =
  createAction<Record<string, Maybe<string> | undefined>>('discretionRequested');
export const exitPrintView = createAction<Record<string, unknown>>('exitPrintView');
export const logExpiredApplication = createAction('logExpiredApplication');
export const logExpiredQuote = createAction('logExpiredQuote');
export const notificationModalClosed =
  createAction<Record<string, string>>('notificationModalClosed');
export const notOfferedRequestCoverageClicked = createAction<Record<string, unknown>>(
  'notOfferedRequestCoverageClicked'
);
export const quoteRePriced = createAction<Record<string, string>>('quoteRePriced');
export const quoteViewed = createAction<Record<string, unknown>>('quoteViewed');
export const quoteDownloaded = createAction<Record<string, unknown>>('quoteDownloaded');
export const toggleDefinitionPanel = createAction<Record<string, string>>('toggleDefinitionPanel');
export const viewPrintView = createAction<Record<string, string>>('viewPrintView');
export const viewAccessDenied = createAction<Record<string, unknown>>('viewAccessDenied');
